.landing-title, .landing-subtitle {
  text-align: center;
  margin: 0 auto;
}

h3.landing-subtitle {
  padding-top: 1rem;
}

@media only screen and (min-width: 768px) {
  h3.landing-subtitle {
    padding-top: 2rem;
  }
  
}
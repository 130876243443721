@import './variables';

h1.landing-title {
  font-family: $body-font;
  font-size: 3.5rem;
}

h2.landing-subtitle {
  font-family: $body-font;
  font-size: 1.5rem;
}

h3.landing-subtitle {
  font-family: $body-font;
  font-size: 1.25rem;
}

.navbar-item {
  font-family: $body-font;
  font-size: 2rem;
}

@media only screen and (max-width: 320px) {
  h1.landing-title {
    font-size: 3rem;
  }
  h2.landing-subtitle {
    font-size: 1.25rem;
  }
  
  h3.landing-subtitle {
    font-size: 1.1rem;
  }
  
}


@media only screen and (min-width: 768px) {
  h1.landing-title {
    font-size: 10rem;
  }
  
  h2.landing-subtitle {
    font-size: 4rem;
  }
  
  h3.landing-subtitle {
    font-size: 2rem;
  }
  
  .navbar-item {
    font-size: 2rem;
  }
  
}

